
   <ng-container *ngIf="truePass else otp">
   <div class="section-card">
        <div class="card_row">
            <div class="img-area bg-img">
                <img class="logo" src="./../assets/logo.png" alt="">
            </div>
            <div class="form-area">
                <div class="form-card">
                    <h3 class="heading">Schedule Generation</h3>
                    <p class="sub-heading">Upload Files to get XML</p>
                    <div class="drop-area-wrapper">
                        <div class="drop-area" [class.drag-over]="isSoaDragOver" (drop)="onDrop($event, 'soa')" (dragover)="onDragOver($event, 'soa')" (dragleave)="onDragLeave($event, 'soa')" id="drop-area">
                          <i class="fa fa-light fa-cloud-arrow-up"></i>
                          <div class="text-column">
                            <h6>Select a file or drag and drop here</h6>
                            <p>{{soaFile ? '1 file selected' : 'Schedule of accommodation (SOA) file'}}</p>
                          </div>
                          <button (click)="soaInput.click()" id="selectFileButton">Select file</button>
                          <input (change)="onSoaFileChange($event)" #soaInput type="file" id="fileElem">
                        </div>
                      
                        <div class="drop-area" [class.drag-over]="isTfDragOver" (drop)="onDrop($event, 'tf')" (dragover)="onDragOver($event, 'tf')" (dragleave)="onDragLeave($event, 'tf')" id="drop-area">
                          <i class="fa-light fa-cloud-arrow-up"></i>
                          <div class="text-column">
                            <h6>Select a file or drag and drop here</h6>
                            <p>{{tfFile ? '1 file selected' : 'Timber Frame deliver dates file'}}</p>
                          </div>
                          <button (click)="tfInput.click()" id="selectFileButton">Select file</button>
                          <input #tfInput (change)="onTfFileChange($event)" type="file" id="fileElem">
                        </div>
                      </div>
                      
    
                    <div class="progress-section">
                        <div class="uploading" *ngIf="uploading">
                            <div class="icon">
                                <i class="fa-solid fa-file fa-sm" style="color: #ac96e4;"></i>
                            </div>
                            <div class="discription-column">
                                <div class="text-column">
                                    <div class="name">
                                        <span class="">Uploading File</span>
                                        <!-- <p>{{(loaded/total)*100}}%</p> -->
                                    </div>
                                    <div class="action-icon">
                                        <!-- <i class="fa-sharp fa-light fa-xmark" style="color: #794fed;"></i> -->
                                    </div>
                                </div>
                                <div class="progress-column">
                                    <div class="progress-bar">
                                        <div class="progress" [style.width.%]="uploadProgress"></div>
                                    </div>
                                    <div class="progress-number">
                                        <span>{{uploadProgress}}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="generating" style=" padding: 10px;" *ngIf="uploadProgress>=100 && !readyToDownload">generating schedule...</span>
                        <div class="success" *ngIf="downloadProgress>0">
                            <div class="icon">
                                <i class="fa-solid fa-file fa-sm" style="color: #73B172;"></i>
                            </div>
                            <div class="discription-column">
                                <div class="text-column">
                                    <div class="name">
                                        <span class="">Downloading File</span>
                                        <!-- <p>12 KB</p> -->
                                    </div>
                                    <div class="action-icon">
                                    </div>
                                </div>
                                <div class="progress-column">
                                    <div class="progress-bar">
                                        <div class="progress" [style.width.%]="downloadProgress"></div> 
                                    </div>
                                    <div class="progress-number">
                                        <span>{{downloadProgress}}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="error" *ngIf="f">
                            <div class="icon">
                                <i class="fa-solid fa-file fa-sm" style="color: #E36363;"></i>
                            </div>
                            <div class="discription-column">
                                <div class="text-column">
                                    <div class="name">
                                        <span class="">picture1.jpeg</span>
                                        <p>6.3 MB</p>
                                    </div>
                                    <div class="action-icon">
                                        <i class="fa-light fa-rotate-right fa-flip-horizontal" style="color: #794fed;"></i>
                                    </div>
                                </div>
                                <div class="progress-column">
                                    <div class="progress-bar">
                                        <div class="progress" style="width: 0%;"></div> 
                                    </div>
                                    <div class="progress-number">
                                        <span>Erro</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="error-section" >
                        <ng-container *ngIf="error">
                        <span class="error"> <span class="icon"><i class="fa-sharp fa-light fa-circle-xmark"></i></span>{{error}}</span>
                    </ng-container>
                    </div>
    
                    <button class="submit-btn" (click)="uploadFiles();initial=!initial" *ngIf="initial" [disabled]="!soaFile || !tfFile" type="submit">Generate</button>
                    <button class="submit-btn" (click)="downloadFile()"  [disabled]="!readyToDownload"  *ngIf="!initial" type="submit">Download Files</button>

                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #otp>

    <div class="section-card">
        <div class="card_row">
            <div class="img-area bg-img">
                <img class="logo" src="../assets/logo.png" alt="">
            </div>
            <div class="form-area otp-area">
                <div class="form-card">
                    <h3 class="heading">Schedule Generation</h3>
                    <p class="sub-heading">Enter OTP</p>
                    
                    <div class="otp-input-container">
                        <input type="password" maxlength="1" class="otp-input" #otp1 (input)="onInput($event, 1)" (keydown)="onKeyDown($event, 1)" />
                        <input type="password" maxlength="1" class="otp-input" #otp2 (input)="onInput($event, 2)" (keydown)="onKeyDown($event, 2)" />
                        <input type="password" maxlength="1" class="otp-input" #otp3 (input)="onInput($event, 3)" (keydown)="onKeyDown($event, 3)" />
                        <input type="password" maxlength="1" class="otp-input" #otp4 (input)="onInput($event, 4)" (keydown)="onKeyDown($event, 4)" />
                        <input type="password" maxlength="1" class="otp-input" #otp5 (input)="onInput($event, 5)" (keydown)="onKeyDown($event, 5)" />
                        <input type="password" maxlength="1" class="otp-input" #otp6 (input)="onInput($event, 6)" (keydown)="onKeyDown($event, 6)" />
                        <input type="password" maxlength="1" class="otp-input" #otp7 (input)="onInput($event, 7)" (keydown)="onKeyDown($event, 7)" />
                        <input type="password" maxlength="1" class="otp-input" #otp8 (input)="onInput($event,8)" (keydown)="onKeyDown($event, 8)" />

                    </div>
    
                    <button class="submit-btn" type="submit" (click)="checkPass()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
