import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { CommonService } from './services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private http: HttpClient, private commonService: CommonService) { }
  isSoaDragOver = false;
  isTfDragOver = false;
  error: null | string = null;
  title = 'AiSchedule';
  filename = ''
  passwordActual = '21d2e8550f5e962a61a35f77de142c381658cf1ebd19e299b057b67948060e75';
  truePass = false;
  password = '';
  downloadProgress: number = 0;
  @ViewChildren('otp1, otp2, otp3, otp4, otp5, otp6, otp7, otp8') otpInputs!: QueryList<ElementRef>;
  onInput(event: any, index: number): void {
    const input = event.target;
    if (input.value.length > 1) {
      input.value = input.value.slice(0, 1);
    }
    if (input.value && index < 8) {
      const nextInput = this.otpInputs.toArray()[index];
      if (nextInput) {
        nextInput.nativeElement.focus();
      }
    }
    this.updatePassword();
  }
  onKeyDown(event: any, index: number): void {
    const input = event.target;
    if (event.key === 'Backspace' && !input.value && index > 1) {
      const prevInput = this.otpInputs.toArray()[index - 2];
      if (prevInput) {
        prevInput.nativeElement.focus();
      }
    }
  }

  resetAllForms() {

    this.soaFile = undefined;
    this.tfFile = undefined;
    this.initial = true;
    this.readyToDownload = false;
  }
  barprogressReset() {
    this.error = null;
    this.uploading = false;
    this.downloadProgress = 0; // Reset progress after download
    this.uploadProgress = 0;
  }

  downloadFile() {
    this.http.get(`https://scheduleai.gagamullercloud.com/api/get-file/?file_name=${this.filename}`, {
      responseType: 'blob',
      observe: 'events',
      reportProgress: true
    }).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.DownloadProgress) {
          if (event.total) {
            this.downloadProgress = Math.round((100 * event.loaded) / event.total);
          }
        } else if (event.type === HttpEventType.Response) {
          const url = window.URL.createObjectURL(event.body!);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.filename}.zip`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url); // Clean up URL
          setTimeout(() => {


            this.resetAllForms();
            this.barprogressReset()
          }, 1000);
        }
      },
      error: (error) => {
        console.error('Error downloading file:', error);
        this.downloadProgress = 0; // Reset progress on error
      }
    });
  }
  updatePassword(): void {
    this.password = Array.from(this.otpInputs.toArray(), (input: ElementRef) => input.nativeElement.value).join('');
  }
  checkPass() {
    let _this = this;
    this.hashString(this.password).then((x: any) => { _this.truePass = (x == this.passwordActual) })
  }
  async hashString(message: string): Promise<string> {
    // Encode the message as a WordArray (utf-8)
    const msgWordArray = CryptoJS.enc.Utf8.parse(message);
    // Hash the message
    const hashWordArray = CryptoJS.SHA256(msgWordArray);
    // Convert the hash to a hex string
    const hashHex = hashWordArray.toString(CryptoJS.enc.Hex);
    return hashHex;
  }
  soaFile: any;
  tfFile: any;
  f = false;
  onSoaFileChange(event: any) {
    this.barprogressReset();
    this.soaFile = event.target.files[0];
  }

  onTfFileChange(event: any) {
    this.barprogressReset();
    this.tfFile = event.target.files[0];
  }

  onDrop(event: any, fileType: string) {
    event.preventDefault();
    this.barprogressReset();
    const file = event.dataTransfer.files[0];
    if (fileType === 'soa') {
      this.isSoaDragOver = false;
      this.soaFile = file;
    } else if (fileType === 'tf') {
      this.isTfDragOver = false;
      this.tfFile = file;
    }
  }
  onDragLeave(event: DragEvent, type: string): void {
    event.preventDefault();
    if (type === 'soa') {
      this.isSoaDragOver = false;
    } else if (type === 'tf') {
      this.isTfDragOver = false;
    }
  }
  uploading = false;
  initial = true;
  readyToDownload = false;
  onDragOver(event: DragEvent, type: string): void {
    event.preventDefault();
    if (type === 'soa') {
      this.isSoaDragOver = true;
    } else if (type === 'tf') {
      this.isTfDragOver = true;
    }
  }

  uploadFiles() {

    let _this = this;
    const formData: FormData = new FormData();
    formData.append('SOA_file', this.soaFile, this.soaFile.name);
    formData.append('TF_DeliveryDates_file', this.tfFile, this.tfFile.name);

    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    this.uploading = true;

    this.http.post<any>('https://scheduleai.gagamullercloud.com/api/predict', formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An unknown error occurred!';

        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred.
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          if (error.status == 404) {
            this.error = 'Api Not Found';
          }
          else {
            this.error = error.error.detail
          }
        }

        _this.resetAllForms();
        return '';
      })
    ).subscribe(
      response => {
        // Handle successful response
        console.log('File uploaded successfully', response);
      },
      error => {
        // Handle error response
        console.error('Error uploading file', error);
      }
    );
  }
  total = 0;
  loaded = 0;
  uploadProgress = 0;
  private getEventMessage(event: HttpEvent<any>, formData: FormData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.uploadProgress = Math.round(100 * event.loaded / event.total!);
        break;
      // return this.fileUploadProgress(event);
      case HttpEventType.Response:

        console.log('response recieved', event);
        this.readyToDownload = true;
        this.filename = event.body.message;


        // return '';
        break;
      default:
      // return `File "${formData.get('SOA_file')}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event: HttpEvent<any>) {
    // const percentDone = Math.round(100 * event.loaded / event.total);
    // const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: `${event}% uploaded.` };
  }

  private apiResponse(event: HttpEvent<any>) {
    // return event.body;
  }
}
