import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
declare var require: any
const FileSaver = require('file-saver');

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    cancelButton: 'btn btn-outline-dark rounded-pill m-2 w-100px',
    confirmButton: 'btn btn-outline-danger rounded-pill m-2 w-100px '
  },
  buttonsStyling: false
})
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private userId = new BehaviorSubject<any>(null);
  preventMultipleCalls = false;
  
  public openChatGroupId = "";
  
contentHeaderTitle:string;
bulkHeaderEvent:any;
  constructor() {
    this.contentHeaderTitle='';
  }
   setUserId(newUserId: any) { 
       this.userId.next(newUserId); 
     }  
  getUserId() 
  {    
    return this.userId.asObservable(); 
   }

confirmDelete(title?:string,text?:string,confirmButtonText?:string,cancelButtonText?:string):Promise<SweetAlertResult<any>>{
   return swalWithBootstrapButtons.fire({
    title: title?title:'Are you sure to delete?',
    text: text?text:"You won't be able to revert this!",
   // type: 'warning',
   icon: 'warning',
    showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    confirmButtonText: confirmButtonText?confirmButtonText:'Yes',
    cancelButtonText: cancelButtonText?cancelButtonText:'No',
    
})
}
confirmDeleteCustomBtns(title?:string,text?:string,btn1?:string,btn2?:string):Promise<SweetAlertResult<any>>{
   return swalWithBootstrapButtons.fire({
    title: title?title:'Are you sure to delete?',
    text: text?text:"You won't be able to revert this!",
   // type: 'warning',
   icon: 'warning',
    showCancelButton: true,
    // confirmButtonColor: '#3085d6',
    // cancelButtonColor: '#d33',
    confirmButtonText: btn1?btn1:'Yes',
    cancelButtonText: btn2?btn2:'No',
    
})
}
confirmDeleteCCTV(title?:string,text?:string):Promise<SweetAlertResult<any>>{
  return swalWithBootstrapButtons.fire({
   title: title?title:'Are you sure to delete?',
   text: text?text:"You won't be able to revert this!",
   position: 'bottom-end',
  // type: 'warning',
  icon: 'warning',
   showCancelButton: true,
   // confirmButtonColor: '#3085d6',
   // cancelButtonColor: '#d33',
   confirmButtonText: 'Yes',
   cancelButtonText: 'No',
   
})
}

downloadFile(location: any, name: any, showGenericToast: boolean = true) {
  FileSaver.saveAs(location, name);
  if(showGenericToast){
    this.showSuccess('Attachment downloaded successfully. View downloads on your device')
  }
}
downloadImage(imageUrl:string,name:string){
  fetch(imageUrl)
  .then(resp => resp.blob())
  .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
  })
  .catch(() => this.showError('An error occured while downloading file'));
}

animateWorkItem(id: any){;
  document.querySelector('tr[data-workItemId="'+id+'"]')?.classList.add('flashItemAnimation');
  setTimeout(()=>{
    document.querySelector('tr[data-workItemId="'+id+'"]')?.classList.remove('flashItemAnimation');
  }, 1500)
}

confirmExit(title?: string, text?: string, type?:string, saveAndExit: any = null, exit: any = null,cancel: any = null){
  return swalWithBootstrapButtons.fire({
    title: title,
    html: text, 
    icon: (type == 'success')? "success": (type == 'warning')? "warning": (type == 'error')? "error": "info",
    customClass: {
      //htmlContainer: 'text-justify',
      cancelButton: 'btn btn-default rounded-pill m-2',
      confirmButton: 'btn btn-primary rounded-pill m-2',
      denyButton: 'btn btn-primary rounded-pill m-2'
    },
    showCancelButton: cancel?true:false,
    showDenyButton: true,
    confirmButtonText: "Save",
    denyButtonText: "Discard",
    cancelButtonText: "Cancel",
    buttonsStyling: false
  }).then(result=>{
    if(result.isConfirmed){
      saveAndExit()
    } else if (result.isDenied){
      exit();
    } else if (result.isDismissed){
      cancel();
    }
  });
}
confirmExitWithCustomButtonText(firstButtonText:string,sencondButtonText:string,title?: string, text?: string, type?:string, saveAndExit: any = null, exit: any = null,cancel: any = null,thirdButtonText="Cancel"){
  return swalWithBootstrapButtons.fire({
    title: title,
    html: text, 
    icon: (type == 'success')? "success": (type == 'warning')? "warning": (type == 'error')? "error": "info",
    customClass: {
      htmlContainer: 'text-justify',
      cancelButton: 'btn btn-default rounded-pill m-2',
      confirmButton: 'btn btn-primary rounded-pill m-2',
      denyButton: 'btn btn-primary rounded-pill m-2'
    },
    showCancelButton: true,
    showDenyButton: true,
    confirmButtonText: firstButtonText,
    denyButtonText: sencondButtonText,
    cancelButtonText: "Cancel",
    buttonsStyling: false
  }).then(result=>{
    if(result.isConfirmed){
      saveAndExit()
    } else if (result.isDenied){
      exit();
    } else if (result.isDismissed){
      cancel();
    }
  });
}

getDisplayStatusClass(status:any):any {
  const statuses = {
    0: "statusDraft",
    1: "statusToDo",
    2: "statusInProgress",
    3: "statusEarlyWarning",
    4: "statusLateWarning",
    5: "statusForApproval",
    6: "At Risk",
    7: "statusForRejected",
    8: "Block",
    9: "statusComplete",
    10: "Escalate",
    11: "Descalate",
    13: "Unknown",
    14: "Delete"
  }
  return statuses[status as keyof typeof statuses] ?? "Unknown";
}
getDisplayStatusClassByStatusName(status:any) {
  const statuses = {
    "draft": "statusDraft",
    "todo": "statusToDo",
    "in_progress": "statusInProgress",
    "early_warning": "statusEarlyWarning",
    "late_warning": "statusLateWarning",
    "for_approval": "statusForApproval",
    "at_risk": "At Risk",
    "rejected": "statusForRejected",
    "block": "Block",
    "complete": "statusComplete",
    "escalate": "Escalate",
    "descalate": "Descalate",
    "unknown": "Unknown",
    "delete": "Delete"
  }
  return statuses[status as keyof typeof statuses] ?? "Unknown";
}

showSuccess(message:string)
{
  swalWithBootstrapButtons.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success!', html: message, icon: 'success' });
}

showWarning(message:string)
{
  swalWithBootstrapButtons.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Warning!', text: message, icon: 'warning' });
}

showError(message:string)
{
  swalWithBootstrapButtons.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Error!', html: message, icon: 'error' });
}

showBulkErrorMessage(errorList:string[]){
  swalWithBootstrapButtons.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Error!',
   html: 
  errorList[0], icon: 'error' });

}

canSendLiveLocation(title:string,text:string):Promise<SweetAlertResult<any>>{
  return swalWithBootstrapButtons.fire({
   title: title,
   text: text,
  // type: 'warning',
  //icon: 'warning',
   showCancelButton: true,
   // confirmButtonColor: '#3085d6',
   // cancelButtonColor: '#d33',
   confirmButtonText: 'Continue',
   cancelButtonText: 'Cancel',
   
})
}

locationConfirmation(title:string,text:string):Promise<SweetAlertResult<any>>{
  return swalWithBootstrapButtons.fire({
   title: title,
   text: text, 
   showCancelButton: true,
   showConfirmButton:false,
   cancelButtonText: 'OK',
   
})
}


}
